import { Excalidraw } from "@excalidraw/excalidraw";

function App() {
  return (
    <>
      <div style={{ height: "100vh" }}>
        <Excalidraw />
      </div>
    </>
  );
}

export default App;
